import { Box, Grid, Typography, styled, Link, Divider } from "@mui/material";
import PageContainer from "../pageContainer";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import GridContainer from "../GridContainer";
import { Colors } from "../../styles/theme";
import ContactDetailsWrapper from "./ContactDetailsWrapper";
import PhoneIphoneRoundedIcon from "@mui/icons-material/PhoneIphoneRounded";
import { useStyle } from "../../hooks/useStyles";
import Person2RoundedIcon from "@mui/icons-material/Person2Rounded";
import RoomRoundedIcon from "@mui/icons-material/RoomRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import ContactForm from "./ContactForm";

const ContactGripWrapper = styled(Box)(() => ({
  padding: "3rem 5rem",
  borderRadius: "1rem",
  fontFamily: `"Open Sans", sans-serif`,
  letterSpacing: "1px",
  color: Colors.black,
}));

const TypoGraphyWrapper = styled(Typography)(() => ({
  fontFamily: `"Open Sans", sans-serif`,
  color: Colors.red,
}));

const Contact = () => {
  const theme = useTheme();

  const gridSize = useMediaQuery(theme.breakpoints.down("md")) ? 12 : 6;

  const iconStyles = useStyle({
    fontSize: "2rem",
    color: Colors.red,
  });

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <PageContainer id="contact" title="Contact">
      <GridContainer>
        <GridContainer>
          <Grid item xs={gridSize}>
            <ContactGripWrapper>
              <Typography variant="subtitle1">
                How can I help you today?
              </Typography>
              <Typography variant="inherit" sx={{ color: Colors.grey }}>
                Please don't hesitate to contact us regarding any questions and
                enquiries you may have.
              </Typography>

              <ContactDetailsWrapper
                title="Name"
                icon={<Person2RoundedIcon sx={iconStyles} />}
              >
                <TypoGraphyWrapper variant="inherit">
                  Hareesh / Sunil
                </TypoGraphyWrapper>
              </ContactDetailsWrapper>

              <ContactDetailsWrapper
                title="Phone"
                icon={<PhoneIphoneRoundedIcon sx={iconStyles} />}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                  }}
                >
                  <Link href={`tel:+917406366662`}>7406366662</Link>
                  {!isMobile ? (
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ bgcolor: Colors.red, margin: "0 8px" }}
                      variant="middle"
                    />
                  ) : null}
                  <Link href={`tel:+917406366662`}>7406366662</Link>
                  {!isMobile ? (
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ bgcolor: Colors.red, margin: "0 8px" }}
                      variant="middle"
                    />
                  ) : null}
                  <Link href={`tel:+917406366662`}>7406366662</Link>
                </Box>
              </ContactDetailsWrapper>

              <ContactDetailsWrapper
                title="Address"
                icon={<RoomRoundedIcon sx={iconStyles} />}
              >
                <TypoGraphyWrapper variant="inherit">
                  No. 5AC-939, 1st block, HRBR Layout, Service Road,
                  Babusapalya, Bengaluru-560043.
                </TypoGraphyWrapper>
              </ContactDetailsWrapper>

              <ContactDetailsWrapper
                title="Email"
                icon={<EmailRoundedIcon sx={iconStyles} />}
              >
                <TypoGraphyWrapper variant="inherit">
                  <Link href="mailto:hareesh.p@vmscout.in">
                    hareesh.p@vmscout.in
                  </Link>
                </TypoGraphyWrapper>
              </ContactDetailsWrapper>
            </ContactGripWrapper>
          </Grid>

          <Grid item xs={gridSize}>
            <ContactForm />
          </Grid>
        </GridContainer>
      </GridContainer>
    </PageContainer>
  );
};

export default Contact;
