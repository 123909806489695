import { Slide } from "@mui/material";
import Box from "@mui/system/Box";
import styled from "@mui/system/styled";
import { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import { Colors } from "../../styles/theme";
import { promotionData } from "../../data";

const PromotionsContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    padding: "10px 0px",
  },
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "5px 0px",
  overflow: "hidden",

  background: `linear-gradient(to bottom right, ${Colors.grey}, ${Colors.black}) fixed`,

  borderRadius: "0  0 10px 10px",
}));

export const PromotionText = styled(Typography)(({ theme }) => ({
  fontFamily: "'DM Serif Display', serif",
  [theme.breakpoints.up("md")]: {
    fontSize: "3rem",
  },
  color: Colors.silver,
  fontSize: "1rem",
}));

const Quotes = () => {
  const [messageIndex, setMessageIndex] = useState(0);
  const [show, setShow] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
    const intervalId = setInterval(() => {
      setMessageIndex((val) => (val + 1) % promotionData?.length);

      setShow(true);

      setTimeout(() => {
        setShow(false);
      }, 3000);
    }, 4000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <PromotionsContainer>
      <Slide
        direction={show ? "left" : "right"}
        in={show}
        timeout={{
          enter: 500,
          exit: 100,
        }}
      >
        <Box>
          <PromotionText>{promotionData[messageIndex]}</PromotionText>
        </Box>
      </Slide>
    </PromotionsContainer>
  );
};

export default Quotes;
