import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import { Colors } from "../../styles/theme";

type PageContainerProps = {
  id: string;
  title: string;
  children?: any;
};

const PageWrapper = styled(Box)(() => ({
  width: "100%",
  minHeight: "100vh",
  borderRadius: "10px",
  overflow: "hidden",
  position: "relative",
  color: `${Colors.black}`,
  boxShadow: "0 10px 30px 5px rgba(0, 0, 0, 0.2)",
  marginTop: "5rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const PageHeader = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "4rem 0",
}));

const PageTitle = styled(Typography)(() => ({
  fontSize: "2rem",
  fontWeight: 600,
  letterSpacing: "1px",
  color: Colors.red,
  fontFamily: `"Open Sans", sans-serif`,
  textTransform: "uppercase",

  "&::before": {
    height: "2.5px",
    display: "block",
    width: "50%",
    background: `${Colors.black}`,
    content: `""`,
    borderRadius: "0 50% 50% 0",
  },

  "&::after": {
    height: "2.5px",
    display: "block",
    width: "50%",
    marginLeft: "auto",
    background: `${Colors.black}`,
    content: `""`,
    borderRadius: "50% 0 0 50%",
  },
}));

const PageBody = styled(Box)(() => ({
  margin: "2rem",
  color: Colors.black,
}));

const PageContainer = ({ id, title, children }: PageContainerProps) => {
  return (
    <PageWrapper id={id}>
      <PageHeader>
        <PageTitle>{title}</PageTitle>
      </PageHeader>
      <PageBody>{children}</PageBody>
    </PageWrapper>
  );
};

export default PageContainer;
