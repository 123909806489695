import { serviceData } from "../../data";
import PageContainer from "../pageContainer";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import { Colors } from "../../styles/theme";

const Service = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <PageContainer id="service" title="Service">
      <ImageList
        sx={{ width: 800 }}
        gap={50}
        cols={isMobile ? 1 : 2}
        style={{ maxWidth: isMobile ? "350px" : "" }}
      >
        {serviceData.map((data) => (
          <ImageListItem key={data.title}>
            <img
              src={`${data.img}?w=248&fit=crop&auto=format`}
              alt={data.title}
              loading="lazy"
            />
            <ImageListItemBar
              title={data.title}
              subtitle="For more details contact us by clicking on the icon."
              actionIcon={
                <IconButton
                  sx={{ color: Colors.red }}
                  aria-label={`info about ${data.title}`}
                  href="#contact"
                >
                  <ArrowForwardRoundedIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </PageContainer>
  );
};

export default Service;
