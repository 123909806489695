import PageContainer from "../pageContainer";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  about1,
  about2,
  about3,
  about4,
  about5,
  about6,
  about7,
  about8,
} from "../../img";
import { aboutDescription } from "../../data";
import { useTheme } from "@mui/material";
import GridContent from "./GridContent";
import GridImage from "./GridImage";
import GridContainer from "../GridContainer";

const About = () => {
  const theme = useTheme();

  const gridSize = useMediaQuery(theme.breakpoints.down("md")) ? 12 : 6;

  return (
    <PageContainer id="about" title="About">
      <GridContainer>
        <GridContent gridSize={gridSize} data={aboutDescription[0]} />
        <GridImage gridSize={gridSize} img={about1} />

        <GridImage gridSize={gridSize} img={about2} />
        <GridContent gridSize={gridSize} data={aboutDescription[1]} />

        <GridContent gridSize={gridSize} data={aboutDescription[2]} />
        <GridImage gridSize={gridSize} img={about3} />

        <GridImage gridSize={gridSize} img={about4} />
        <GridContent gridSize={gridSize} data={aboutDescription[3]} />

        <GridContent gridSize={gridSize} data={aboutDescription[4]} />
        <GridImage gridSize={gridSize} img={about5} />

        <GridContent gridSize={gridSize} data={aboutDescription[5]} />
        <GridContent gridSize={gridSize} data={aboutDescription[6]} />

        <GridImage gridSize={gridSize} img={about6} />
        <GridContent gridSize={gridSize} data={aboutDescription[7]} />

        <GridContent gridSize={gridSize} data={aboutDescription[8]} />
        <GridContent gridSize={gridSize} data={aboutDescription[9]} />

        <GridContent gridSize={gridSize} data={aboutDescription[10]} />
        <GridImage gridSize={gridSize} img={about7} />

        <GridContent gridSize={gridSize} data={aboutDescription[11]} />
        <GridContent gridSize={gridSize} data={aboutDescription[12]} />

        <GridImage gridSize={gridSize} img={about8} />
        <GridContent gridSize={gridSize} data={aboutDescription[13]} />

        <GridContent gridSize={gridSize} data={aboutDescription[14]} />
        <GridContent gridSize={gridSize} data={aboutDescription[15]} />
      </GridContainer>
    </PageContainer>
  );
};

export default About;
