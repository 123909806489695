import Container from "@mui/material/Container";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./styles/theme";
import Navbar from "./components/navbar";
import Banner from "./components/banner";
import Quotes from "./components/quotes";
import About from "./components/about";
import Service from "./components/service";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import NavbarDrawer from "./components/drawer";
import { UIProvider } from "./contexts";
import Contact from "./components/contact";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl" sx={{ background: "#fff" }}>
        <UIProvider>
          <ToastContainer />
          <Navbar />
          <Banner />
          <Quotes />
          <About />
          <Service />
          <Contact />
          <NavbarDrawer />
        </UIProvider>
      </Container>
    </ThemeProvider>
  );
}

export default App;
