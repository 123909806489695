import Grid from "@mui/material/Grid";
import { useStyle } from "../../hooks/useStyles";

const GridContainer = ({ children }: any) => {
  const gridStyle = useStyle({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  });
  return (
    <Grid
      container
      rowSpacing={7}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      style={gridStyle}
    >
      {children}
    </Grid>
  );
};

export default GridContainer;
