import AppRegistrationRoundedIcon from "@mui/icons-material/AppRegistrationRounded";
import PaymentRoundedIcon from "@mui/icons-material/PaymentRounded";
import {
  Box,
  Divider,
  ListItemButton,
  ListItemIcon,
  Typography,
  styled,
} from "@mui/material";
import { Colors } from "../../styles/theme";
import { toast } from "react-toastify";
import { ListItems } from "../../styles/generalStyles";

type ApplicationProps = {
  isMobile: boolean;
  scrollPosition: number;
};

const ApplicationMobile = styled(Box)(() => ({
  display: "flex",
  position: "fixed",
  bottom: 0,
  left: 0,
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 99,
  borderTop: "1px solid ",
  background: Colors.black,
  fontFamily: `"Raleway", sans-serif`,
}));

const ApplicationDesktop = styled(Box)(() => ({
  fontFamily: `"Raleway", sans-serif`,
}));

const Application = ({ isMobile, scrollPosition }: ApplicationProps) => {
  const ListItemIconStyle = styled(ListItemIcon)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: Colors.red,
  }));

  const ApplicationContainer = isMobile
    ? ApplicationMobile
    : ApplicationDesktop;

  const comingSoonToast = () => {
    toast.info("Coming soon", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  return (
    <ApplicationContainer>
      <ListItems type="row">
        <ListItemButton onClick={comingSoonToast}>
          <ListItemIconStyle>
            <AppRegistrationRoundedIcon />
            <Typography variant="subtitle1" sx={{ marginLeft: "0.2rem" }}>
              App
            </Typography>
          </ListItemIconStyle>
        </ListItemButton>

        <Divider
          orientation="vertical"
          flexItem
          sx={{ bgcolor: isMobile ? Colors.silver : Colors.silver }}
          variant="middle"
        />

        <ListItemButton onClick={comingSoonToast}>
          <ListItemIconStyle>
            <PaymentRoundedIcon />
            <Typography variant="subtitle1" sx={{ marginLeft: "0.2rem" }}>
              Payment
            </Typography>
          </ListItemIconStyle>
        </ListItemButton>
      </ListItems>
    </ApplicationContainer>
  );
};

export default Application;
