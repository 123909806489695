import { Box, ListItemButton, Typography } from "@mui/material";
import styled from "@mui/material/styles/styled";
import { useStyle } from "../../hooks/useStyles";
import { logo } from "../../img";
import { navBarListData } from "../../data";
import { Colors } from "../../styles/theme";
import Application from "./Application";
import { ListItems } from "../../styles/generalStyles";

type NavbarDesktopProps = {
  isMobile: boolean;
  scrollPosition: number;
};

const NavBarContainer = styled(Box)(() => ({
  display: "flex",
  marginTop: 4,
  justifyContent: "space-between",
  alignItems: "center",
  padding: "2px 8px",
  zIndex: 999,
  position: "sticky",
  top: 0,
  transition: "all 0.5s ease",
  background: "#fff",
  borderRadius: "0 0 10px 10px",
}));

export const ListItemTextStyle = styled(Typography)(() => ({
  color: Colors.black,
  fontFamily: `"Open Sans", sans-serif`,
  fontSize: "20px",
  fontWeight: 500,
}));

const NavbarDesktop = ({ isMobile, scrollPosition }: NavbarDesktopProps) => {
  const logoStyle = useStyle({
    width: "120px",
  });

  return (
    <NavBarContainer
      sx={{
        boxShadow:
          scrollPosition > 40 ? "0px 10px 20px -8px rgba(0,0,0,0.58)" : "",
      }}
    >
      <img src={logo} alt="vmscout" style={logoStyle} />

      <ListItems type="row">
        {navBarListData.map((data) => (
          <ListItemButton href={`${data.path}`} key={data.path}>
            <ListItemTextStyle>{data.title}</ListItemTextStyle>
          </ListItemButton>
        ))}
      </ListItems>

      <Application isMobile={isMobile} scrollPosition={scrollPosition} />
    </NavBarContainer>
  );
};

export default NavbarDesktop;
