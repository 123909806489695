import Application from "./Application";
import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import { logo } from "../../img";
import { useStyle } from "../../hooks/useStyles";
import { Colors } from "../../styles/theme";
import { useUIContext } from "../../contexts";
import { IconButton } from "@mui/material";

type NavbarMobileProps = {
  isMobile: boolean;
  scrollPosition: number;
};

const NavBarMobileContainer = styled(Box)(() => ({
  display: "flex",
  padding: "15px 5px",
  zIndex: 999,
  position: "sticky",
  top: 0,
  transition: "all 0.5s ease",
  background: "#fff",
  borderRadius: "0 0 10px 10px",
}));

const NavbarWrapper = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
}));

const OpenButton = styled(IconButton)(() => ({
  background: Colors.black,
}));

const NavbarMobile = ({ isMobile, scrollPosition }: NavbarMobileProps) => {
  const logoStyle = useStyle({
    width: "120px",
  });

  const { setDrawerOpen } = useUIContext();

  return (
    <NavBarMobileContainer
      sx={{
        boxShadow:
          scrollPosition > 40 ? "0px 10px 20px -8px rgba(0,0,0,0.58)" : "",
      }}
    >
      <NavbarWrapper>
        <OpenButton onClick={() => setDrawerOpen(true)}>
          <KeyboardDoubleArrowRightRoundedIcon
            sx={{ color: Colors.red, fontSize: "2rem" }}
          />
        </OpenButton>
        <img src={logo} alt="vmscout" style={logoStyle} />
        <div></div>
      </NavbarWrapper>
      <Application isMobile={isMobile} scrollPosition={scrollPosition} />
    </NavBarMobileContainer>
  );
};

export default NavbarMobile;
