import { Box, styled, Typography } from "@mui/material";

import { Colors } from "../../styles/theme";

const ContactDetailsContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  marginTop: "1rem",
}));

const ContactDetailsWrapper = ({ title, icon, children }: any) => {
  return (
    <ContactDetailsContainer>
      {icon}
      <Box sx={{ marginLeft: "0.7rem" }}>
        <Typography
          variant="subtitle2"
          sx={{
            fontFamily: `"Open Sans", sans-serif`,
            color: Colors.black,
            fontWeight: "600",
          }}
        >
          {title}
        </Typography>
        <>{children}</>
      </Box>
    </ContactDetailsContainer>
  );
};

export default ContactDetailsWrapper;
