import { Control, Controller } from "react-hook-form";
import { CSSProperties } from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Select as MuiSelect } from "@mui/material";

export type SelectOption = {
  value?: any;
  label?: string;
};

type SelectProps = {
  control: Control;
  name: string;
  label: string;
  sx?: CSSProperties;
  options: SelectOption[];
};

const Select = ({ control, name, label, sx, options }: SelectProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <FormControl variant="standard" sx={sx}>
          <InputLabel>{label}</InputLabel>
          <MuiSelect value={value} onChange={onChange}>
            {options &&
              options.map((data, index) => {
                const { value, label } = data;
                return (
                  <MenuItem value={value} key={label}>
                    {data?.label}
                  </MenuItem>
                );
              })}
          </MuiSelect>
        </FormControl>
      )}
    />
  );
};

export default Select;
