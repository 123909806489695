import { Control, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { CSSProperties, HTMLInputTypeAttribute } from "react";

type InputProps = {
  control: Control;
  name: string;
  defaultValue?: string;
  label: string;
  sx?: CSSProperties;
  required?: boolean;
  onChange?: (value: any) => void;
  type?: HTMLInputTypeAttribute;
};

const Input = ({
  control,
  name,
  label,
  sx,
  defaultValue,
  required,
  onChange,
  type = "text",
}: InputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange: test, value } }) => (
        <TextField
          value={value}
          label={label}
          variant="standard"
          sx={{ width: "45%" }}
        />
      )}
    />
  );
};

export default Input;
