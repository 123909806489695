import { createTheme } from "@mui/material/styles";

export const Colors = {
  // primary: "hsl(190, 64%, 22%)",
  // secondary: "hsl(190, 64%, 22%)",
  // primaryAlt: "hsl(190, 64%, 15%)",
  // titleColor: "hsl(190, 64%, 18%)",
  // textColor: "hsl(190, 24%, 35%)",
  // textColorLight: "hsl(190, 8%, 60%)",
  // inputColor: "hsl(190, 24%, 97%)",
  // bodyColor: "hsl(190, 100%, 99%)",
  // whiteColor: "#FFF",
  // scrollBarColor: "hsl(190, 12%, 90%)",
  // scrollThumbColor: "hsl(190, 12%, 75%)",
  // black: "#000000",

  black: "#2b2d42",
  red: "#d90429",
  lightRed: "#ef233c",
  orange: "#DC5F00",
  silver: "#edf2f4",
  grey: "#8d99ae",
};

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.red,
    },
    secondary: {
      main: Colors.black,
    },
  },

  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
    },

    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: 250,
          color: Colors.black,
          borderRight: `2px solid ${Colors.red}`,
          borderRadius: "0 0.4rem 0.4rem 0",
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
  },
});

export default theme;
