import { styled } from "@mui/material";
import Grid from "@mui/material/Grid";

type GridImageProps = {
  gridSize: 12 | 6;
  img: string;
};

const AboutImage = styled("img")(({ src }) => ({
  src: `url(${src})`,
  width: "450px",
  padding: "3rem",
}));

const GridImage = ({ gridSize, img }: GridImageProps) => {
  return (
    <Grid
      item
      xs={gridSize}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <AboutImage src={img} />
    </Grid>
  );
};

export default GridImage;
