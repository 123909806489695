import Grid from "@mui/material/Grid";
import styled from "@mui/material/styles/styled";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Colors } from "../../styles/theme";

type GridContentProps = {
  gridSize: 12 | 6;
  data: {
    title: string;
    description: string;
  };
};

const ContentStyle = styled(Paper)(() => ({
  padding: "3rem",
  borderRadius: "1rem",
  fontFamily: `"Open Sans", sans-serif`,
  letterSpacing: "1px",
  color: Colors.black,
  boxShadow: "9px 10px 37px 0px rgba(0,0,0,0.58)",
}));

const ContentTitle = styled(Typography)(() => ({
  fontFamily: `"Open Sans", sans-serif`,
  fontSize: "1.5rem",
  color: Colors.black,
  marginBottom: "10px",
}));

const ContentDesc = styled(Typography)(() => ({
  fontFamily: `"Open Sans", sans-serif`,
  fontSize: "1rem",
  color: Colors.grey,
}));

const GridContent = ({ gridSize, data }: GridContentProps) => {
  const { title, description } = data;

  return (
    <Grid item xs={gridSize}>
      <ContentStyle>
        <ContentTitle>{title}</ContentTitle>
        <ContentDesc>{description}</ContentDesc>
      </ContentStyle>
    </Grid>
  );
};

export default GridContent;
