import { Divider, Drawer, List, ListItemButton, Box } from "@mui/material";
import { navBarListData } from "../../data";
import { ListItemTextStyle } from "../navbar/NavbarDesktop";
import { Colors } from "../../styles/theme";
import { useUIContext } from "../../contexts";

import styled from "@mui/material/styles/styled";
import IconButton from "@mui/material/IconButton";

import KeyboardDoubleArrowLeftRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftRounded";

const CloseButton = styled(IconButton)(() => ({
  position: "fixed",
  top: 10,
  left: "250px",
  background: Colors.black,
  zIndex: 1999,
}));

const NavbarDrawer = () => {
  const { drawerOpen, setDrawerOpen } = useUIContext();
  return (
    <>
      {drawerOpen ? (
        <CloseButton onClick={() => setDrawerOpen(false)}>
          <KeyboardDoubleArrowLeftRoundedIcon
            sx={{ fontSize: "2rem", color: Colors.red }}
          />
        </CloseButton>
      ) : null}
      <Drawer open={drawerOpen}>
        <List>
          {navBarListData.map((data) => (
            <Box key={data.title}>
              <ListItemButton
                href={`${data.path}`}
                key={data.path}
                onClick={() => setDrawerOpen(false)}
              >
                <ListItemTextStyle>{data.title}</ListItemTextStyle>
              </ListItemButton>
              <Divider variant="middle" style={{ background: Colors.red }} />
            </Box>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default NavbarDrawer;
