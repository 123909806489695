import styled from "@mui/material/styles/styled";
import List from "@mui/material/List";
import Box from "@mui/material/Box";

export const ListItems = styled(List)(
  ({ type }: { type: "row" | "column" }) => ({
    display: type === "row" ? "flex" : "block",
    justifyContent: "center",
    alignItems: "center",
  })
);

export const PageWrapper = styled(Box)(() => ({
  width: "100%",
  minHeight: "100vh",
  borderRadius: "10px",
  overflow: "hidden",
  position: "relative",
  boxShadow: "0 10px 30px 5px rgba(0, 0, 0, 0.2)",
  marginTop: "5rem",
}));
