import { SelectOption } from "../components/select";
import { home, shop, buildings, warehouse } from "../img";

export const navBarListData = [
  {
    title: "Home",
    path: "#",
  },
  {
    title: "About",
    path: "#about",
  },
  {
    title: "Service",
    path: "#service",
  },
  {
    title: "Contact",
    path: "#contact",
  },
];

export const promotionData = [
  "Prevention and protection is our goal",
  "24/7 security checker at our best",
  "Securing your future in the digital world",
];

export const aboutDescription = [
  {
    title: "More Information About The VMScout",
    description:
      "VMScout is a next-gen security solution for businesses that provides CCTV service monitored by experts at ultra-affordable cost. The remote guarding industry is relatively new to India, and we are on a mission to change how the virtual security guard services work in our country. One of the reasons why companies and shops choose remote guarding security service is because it’s more convenient and efficient and cost-effective in every way possible. The only requirements from the client side would be an uninterrupted internet facility and power supply to the camera. All the other supply / items / camera / wires / connection charges and so on will be provided by VMScout. We will even provide insurance on your shop under which if any kind of theft occurs you would receive an amount of upto 1 Lakh.",
  },
  {
    title: "What We Do?",
    description:
      "Well, with Technological advancements in the past few decades, the entire world has changed so much. People need to protect personnel and their valuable assets. There are many ways to protect our valuable assets like maximizing security which is done in many ways one of them is Virtual Digital Monitoring. Through the changes in time and technology, Virtual monitoring is of the advanced techniques used to monitor houses, industries, warehouses, shops, ATM, schools, buildings, public sectors and real estate for avoiding the chances of theft monitoring the perimeter of the property and visual crime deterrent.",
  },
  {
    title: "What We Provide?",
    description:
      "Thousands of companies, industries, and real estate have turned to Virtual Monitoring services as a highly effective tool for their business security needs. No team can provide a solution concerning threat awareness and crime prevention with our Virtual Monitoring solutions. A virtual security service is more affordable. VMScouts remove on-site surveillance and can cost up to 80% less than an on-site security guard.",
  },
  {
    title: "What We Want?",
    description:
      "The client side's only requirement would be an uninterrupted internet facility with static IP and power supply to the camera. VMScouts will provide all the other supply / items / camera / wires / connection charges, and so on. Please contact VMScout today for Virtual Security service to protect your property and a free quote and consultation for remote video monitoring with a virtual security guard.",
  },
  {
    title: "Off-Site Security Vs On-Site Security",
    description:
      "For lot of people who don't know what CCTV is, known as Closed-Circuit Television is a Video Surveillance which is used for monitoring places through cameras. The specialty of CCTV cameras is that they can be Monitored in any part of the world. The world is working towards drastic changes in creating technologies and advancements. The increase in security threats has been increasing day by day and has facilitated the introduction of technologies to several agencies. These security agencies have taken good advantage of the technology and are using it for public causes and safety. Not only used by security agencies but has come to use by common people. Out of 195 countries, India has been ranked at 65th place when it comes to crime. According to sources, every 10 minutes, a crime is committed around the world. So it would be better to keep your houses and properties Safe and Secure.",
  },
  {
    title: "",
    description:
      "You Might be wondering that you hired a security guard, then Why Install a CCTV? Here are a few Reasons why Off-site Security is better than On-site Security. On-site security is nothing but hiring a Security Guard to Protect your Premises and Properties according to your needs and requirements. Many agencies offer these services to various companies, apartments and private houses too. Basic unarmed security works for 24000 a month depending on the place of work, and it may vary depending on the skills, advance techniques, and experience of the Security guard, which might come up to 40000. Moreover, all the insurance costs and hiring costs may not be within the budget. An On-Site security guard can't monitor a 360 view, and in that case, you will have to hire a minimum of 3 Guards, which might not be under the budget. At times Security guards end up with boredom which might lead to mistakes and Ignorance of Crime. The motive here is not to point out the pros and cons of On-site Security but to suggest installing an Off-site Security Guard.",
  },
  {
    title: "",
    description:
      "On-site Security solutions like VMScout Services access and monitor live feed from any part of a place. CCTV Camera services can beneﬁt in many ways. Through CCTV Camera services, we can employ complex AI analytics and live monitoring agents to determine suspicious activity and catch events in the act or even before they occur. They are quite useful and protect not just your properties but your loved ones too. We can even alert the On-site security personnel available on the site or others on-site or the authorities who act on the situation and stop the threats. This means you can setup and establish gate access points that are automated and unmanned - a safer solution for everyone. The Security Personnel might be ignorant, but CCTV cameras can monitor the 360 views and actions of everyone. VMScouts offer you the best CCTV camera services for your houses, shops, buildings, and warehouses. When it comes to Installation, Get a Quotation through our Experts, which will be under your budget. To get a Quotation, Click Here.",
  },
  {
    title: "How Much CCTV Cameras Do I Need?",
    description:
      "If you are thinking of installing a CCTV Camera, You might be thinking about how many CCTV cameras might be in need to ﬁt your property? Not having enough CCTV cameras means you can't monitor alot of areas. Having a lot of cameras is just a waste of money. It can be a confusing task choosing how many cameras you might need. Before buying CCTV Cameras, you should give a thought to why you will be using these cameras. Determining the purpose of security cameras will allow you to think about how many cameras you will need. For example, if you are installing a CCTV Camera outside your house, i.e., at the entry point, then your purpose is to monitor any crime that happens outside your house.",
  },
  {
    title: "",
    description:
      "After deciding out the purpose, the next step is to ﬁgure out which areas you want cameras to be installed. First of all, it depends on where you're living. If you need a Camera at your House premises, it is important to determine how many CCTV cameras might be in need. It is better to have 4 CCTV Cameras at your house. ● Front of the House which includes the parking space too. ● The backyard includes any point of entry from the Back doors or gates. ● In the Living Room. ● In the children's room. ● If any valuables are kept in a room. Inmost cases, you will need 4 CCTV Cameras and that would be sufficient. However, not all homes are the same and each differs from the other. ● Bigger houses might need more CCTV Cameras asthere are more rooms to monitor. ● Property size: If your house is situated in a large plot, you will need more cameras to monitor a 360 View.",
  },
  {
    title: "",
    description:
      "When it comes to businesses, warehouses, and departmental stores, you will need a minimum of 10 CCTV Cameras. There will be a necessary of 8-10 Cameras to cover the entire store. A Security Camera can cover up to 0-70 feet depending on the resolution, lens, sensor. Once you've decided the purpose of installing CCTV Cameras, it is important to ﬁgure out where to place the cameras in case of shops, warehouses, and buildings. ● The most important area to place the camera is the cash counter. The valuable thing in the world is money, and it is safe to keep an eye on it. ● The other important area to place the camera is at the warehouses. It is pretty important because this is where the inventory is stored. If in case it is stolen or caught on ﬁre, the camera would determine the reason and generate proof so that it would be easy for the owner to claim insurance in case of ﬁre or ﬁnd the thief in case of theft. ● Buildings are another place where the CCTV cameras should be placed. 90% of the Buildings have cameras at the entry-level and exit levels. The motive is to know who visits the buildings, secure most of the vehicles parked in the buildings and acts as a witness if any crime is being committed in front of the building",
  },
  {
    title: "How Much Power Does A CCTV System Consume?",
    description:
      "Having a CCTV Camera System at your house or business is beneﬁcial and gives protection and security to your premises. However, CCTV is a Long term Investment, and it is better to consider all the factors while you decide to install CCTV camera Services. CCTV Camera Services might consume alot of bandwidth, but they forget the main factor in how much power is consumed as they work 24/7 or at least for most of the day. So it is important to ﬁgure out how much power is consumed and how much you will be paying as an electric bill. We can help you estimate how much CCTV Cameras can consume. When you are looking for camera speciﬁcations, they usually come up with a power supply and its consumption. If there is no power consumption listed, then you can easily calculate using the power supply information.",
  },
  {
    title: "",
    description:
      "Security cameras typically do not consume as much energy as compared to other electronics in the house, such as computers, microwaves. A Refrigerator or a DVD can consume more energy than security cameras,especially when they are set to record 24/7.",
  },
  {
    title: "",
    description:
      "You shouldn't think that CCTV System consumes Extraordinary Energy and power.If you are using it in your house, then the electric bill won't go above your budget.",
  },
  {
    title: "What CCTV Accessories Do I Need?",
    description:
      "Finally, you've come to a conclusion to install CCTV cameras on your premises, and you have to take time to choose the exact security camera you need as you want to use it. Does your system have all the necessary accessories? Just in case you are Installing CCTV Cameras or Video Surveillance into your houses or large businesses for the very ﬁrst time, do you have all the necessary accessories? Well,there are a few accessories you must purchase before your system is complete.",
  },
  {
    title: "",
    description:
      "● Cables: The Cables that you connect to CCTV are important as the CCTV cameras Installed. Generally, BNC Cables are used in Analog Cameras, and Ethernet Cables are used with IP Cameras. ● Power Supply: All Wired Security Systems will require a power supply to power the Cameras. ● Hard disk: One of the most important accessories is the Hard Disk. Where do you think all the footage will be stored? Without the Hard Disk Drive, nothing will be recorded on the system. For a typical homeowner, it is necessary for them to maintain two weeks of data and footage. But when it comes to Large businesses and Buildings, it is necessary for them to maintain three months of data and footage. Remember, the more the storage, the more the cost of the Hard Disk. On a note, other factors might eat up space, such as higher resolutions, continuous recordings. ● USB FAT 32 Format: Just as important as the Hard Drive is, so is USB 32 Format. It's nothing but an external device that can store data. Once the Hard Drive reaches maximum capacity, there is a need for an external device to copy all the data from the hard drive and erase it so that it creates room for new data. By doing this, you'll be able to save the old recordings.",
  },
  {
    title: "",
    description:
      "● Monitor and Mouse: Other CCTV Accessories you should have included a Monitor and Mouse. A Monitor is used to view all the Recordings from the CCTV, and the mouse is used for controlling the setting on the video recorder. While choosing a Monitor, it is better to opt to use one that will give you the best image quality. ● Internet Source: Having an Internet source is not really an accessory, but it is a necessary item if you want to access the CCTV Camera. By connecting your system to the internet, you'll be able to access, view, and manage the data and the footage. These are some necessary CCTV Cameras or Security cameras Accessories you will need in case of Installing CCTV. Still, Confused? Confused about the Installation Charges? Don't know whom to contact? You have nothing to worry about. VMScout Offers the Best Service and Prices, which will be under Your Budget. In the difficult times of Covid-19, we assure you of Safe and Secure Services to your Properties. It is safe to install a CCTV Camera or Security Camera. We provide you with Smoke Dome Cameras, Dome Cameras, and Outdoor Cameras with Alarm Port.",
  },
];

export const serviceData = [
  {
    img: shop,
    title: "Shop",
  },
  {
    img: home,
    title: "House",
  },
  {
    img: buildings,
    title: "Buildings",
  },
  {
    img: warehouse,
    title: "Warehouse",
  },
];

export const serviceType: SelectOption[] = [
  {
    label: "Home",
    value: "home",
  },
  {
    label: "Shop",
    value: "shop",
  },
  {
    label: "Apartments",
    value: "apartments",
  },
  {
    label: "Warehouse",
    value: "warehouse",
  },
];
