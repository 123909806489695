import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import { banner } from "../../img";
import { Colors } from "../../styles/theme";

const BannerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  height: "100%",
  background: `linear-gradient(to bottom right, ${Colors.lightRed}, ${Colors.red}) fixed`,
  borderRadius: "10px 10px 0 0",
  margin: "20px 0",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const BannerImage = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  borderRadius: "20px",
  width: "400px",
  padding: "50px",
  [theme.breakpoints.down("md")]: {
    width: "350px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "320px",
    height: "300px",
  },
}));

const BannerContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "50px",
}));

const BannerTitle = styled(Typography)(({ theme }) => ({
  fontSize: "72px",
  marginBottom: "20px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "42px",
  },
  color: Colors.silver,
}));

const BannerDescription = styled(Typography)(({ theme }) => ({
  lineHeight: 1.25,
  letterSpacing: 1.25,
  marginBottom: "3em",
  maxWidth: "700px",
  [theme.breakpoints.down("md")]: {
    lineHeight: 1.15,
    letterSpacing: 1.15,
    marginBottom: "1.5em",
  },
  color: Colors.black,
}));

const Banner = () => {
  return (
    <BannerContainer>
      <BannerImage src={banner} />
      <BannerContent>
        <Typography variant="h6" sx={{ color: Colors.silver }}>
          Professional security wasn't available,
        </Typography>
        <BannerTitle variant="h2">So we fixed it.</BannerTitle>
        <BannerDescription variant="subtitle1">
          VMScout is a next-gen security solution for businesses that provides
          CCTV service monitored by experts at ultra-affordable cost.
        </BannerDescription>
      </BannerContent>
    </BannerContainer>
  );
};

export default Banner;
