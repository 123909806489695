import { Box, Paper, styled, Typography } from "@mui/material";
import Input from "../input";
import { useForm } from "react-hook-form";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import Select from "../select";
import Button from "@mui/material/Button";
import { useStyle } from "../../hooks/useStyles";
import { Colors } from "../../styles/theme";
import { serviceType } from "../../data";
import { useCallback } from "react";

import { toast } from "react-toastify";

const ContactFormWrapper = styled(Paper)(() => ({
  padding: "3rem",
  borderRadius: "1rem",
  fontFamily: `"Open Sans", sans-serif`,
  boxShadow: "9px 10px 37px 0px rgba(0,0,0,0.58)",
}));

const ContactFormHeaderWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const ContactForm = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const namePhoneStyled = useStyle({
    display: "flex",
    justifyContent: "space-between",
  });

  const { control } = useForm();

  const onSubmit = useCallback(() => {
    toast.info("Coming soon", {
      position: toast.POSITION.TOP_CENTER,
    });
  }, []);

  return (
    <ContactFormWrapper>
      <ContactFormHeaderWrapper>
        <Typography
          variant="h4"
          color={Colors.red}
          sx={{ marginBottom: "3rem" }}
        >
          Message us
        </Typography>
      </ContactFormHeaderWrapper>

      <div style={isMobile ? {} : namePhoneStyled}>
        <Input
          control={control}
          name="name"
          label="Name"
          required
          sx={{ width: isMobile ? "100%" : "40%" }}
        />
        <Input
          control={control}
          name="phone"
          label="Phone"
          type="number"
          sx={{
            marginLeft: isMobile ? "" : "1rem",
            width: isMobile ? "100%" : "40%",
          }}
          required
        />
      </div>
      <Select
        control={control}
        name="service"
        label="Service"
        sx={{ width: "100%", margin: "1rem 0" }}
        options={serviceType}
      />
      <Button
        variant="contained"
        sx={{ width: "100%", marginTop: "2rem" }}
        type="submit"
        onClick={onSubmit}
      >
        Submit
      </Button>
    </ContactFormWrapper>
  );
};

export default ContactForm;
